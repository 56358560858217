import { Component, OnInit, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  @Input()
  shrink: boolean

  constructor(
    private el: ElementRef
  ) { }

  ngOnInit() {
    
  }

}
