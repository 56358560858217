import { Component, OnInit, ElementRef } from '@angular/core'
import {TranslateService} from '@ngx-translate/core'
import { ActivatedRoute } from '@angular/router';
import { MailerService } from './mailer.service'
import { Client } from './client'


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute
  ) {
    
    translate.setDefaultLang('pt-br')
  }


}
