import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { TextMaskModule } from "angular2-text-mask";
import { AppComponent } from "./app.component";
import { MailerService } from "./mailer.service";
import { AppRoutingModule } from "./app-routing.module";
import { NavComponent } from "./nav/nav.component";
import { HomeComponent } from "./home/home.component";
import { ResourcesComponent } from "./resources/resources.component";
import { ResourcesNavComponent } from "./resources-nav/resources-nav.component";
import { SecretariaComponent } from "./secretaria/secretaria.component";
import { FinanceiroComponent } from "./financeiro/financeiro.component";
import { PedagogicoComponent } from "./pedagogico/pedagogico.component";
import { BibliotecaComponent } from "./biblioteca/biblioteca.component";
import { EadComponent } from "./ead/ead.component";
import { MobileComponent } from "./mobile/mobile.component";
import { PortariaComponent } from "./portaria/portaria.component";
import { IndicateComponent } from "./indicate/indicate.component";
import { BranchComponent } from "./branch/branch.component";
import { ContactFormComponent } from "./contact-form/contact-form.component";
import { FooterComponent } from "./footer/footer.component";
import { ContactComponent } from "./contact/contact.component";
import { AlunoComponent } from "./aluno/aluno.component";
import { ProfessorComponent } from "./professor/professor.component";
import { ScrollToModule } from "@nicky-lenaers/ngx-scroll-to";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateService } from "@ngx-translate/core";

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    TextMaskModule,
    AppRoutingModule,
    HttpClientModule,
    ScrollToModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  declarations: [
    AppComponent,
    NavComponent,
    HomeComponent,
    ResourcesComponent,
    ResourcesNavComponent,
    SecretariaComponent,
    FinanceiroComponent,
    PedagogicoComponent,
    BibliotecaComponent,
    EadComponent,
    MobileComponent,
    PortariaComponent,
    AlunoComponent,
    ProfessorComponent,
    IndicateComponent,
    BranchComponent,
    FooterComponent,
    ContactFormComponent,
    ContactComponent,
  ],
  providers: [MailerService],
  bootstrap: [AppComponent],
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
