import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-portaria',
  templateUrl: './portaria.component.html',
  styleUrls: ['./portaria.component.css']
})
export class PortariaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
