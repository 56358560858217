import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./home/home.component";
import { IndicateComponent } from "./indicate/indicate.component";
import { BranchComponent } from "./branch/branch.component";
import { ResourcesComponent } from "./resources/resources.component";
import { SecretariaComponent } from "./secretaria/secretaria.component";
import { PedagogicoComponent } from "./pedagogico/pedagogico.component";
import { AlunoComponent } from "./aluno/aluno.component";
import { ProfessorComponent } from "./professor/professor.component";
import { MobileComponent } from "./mobile/mobile.component";
import { FinanceiroComponent } from "./financeiro/financeiro.component";
import { BibliotecaComponent } from "./biblioteca/biblioteca.component";
import { EadComponent } from "./ead/ead.component";
import { PortariaComponent } from "./portaria/portaria.component";
import { ContactFormComponent } from "./contact-form/contact-form.component";
import { ContactComponent } from "./contact/contact.component";

const routes: Routes = [
  {
    path: "",
    component: AppComponent,
    children: [
      {
        path: "",
        component: HomeComponent,
      },
      {
        path: "indicate",
        component: IndicateComponent,
      },
      {
        path: "branch",
        component: BranchComponent,
      },
      {
        path: "contact",
        component: ContactComponent,
      },
      {
        path: "resources",
        component: ResourcesComponent,
        children: [
          {
            path: "secretaria",
            outlet: "resources-content",
            component: SecretariaComponent,
          },
          {
            path: "financeiro",
            outlet: "resources-content",
            component: FinanceiroComponent,
          },
          {
            path: "pedagogico",
            outlet: "resources-content",
            component: PedagogicoComponent,
          },
          {
            path: "biblioteca",
            outlet: "resources-content",
            component: BibliotecaComponent,
          },
          {
            path: "ead",
            outlet: "resources-content",
            component: EadComponent,
          },
          {
            path: "mobile",
            outlet: "resources-content",
            component: MobileComponent,
          },
          {
            path: "portaria",
            outlet: "resources-content",
            component: PortariaComponent,
          },
          {
            path: "aluno",
            outlet: "resources-content",
            component: AlunoComponent,
          },
          {
            path: "professor",
            outlet: "resources-content",
            component: ProfessorComponent,
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
