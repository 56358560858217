import { Component, OnInit } from '@angular/core'
import {FormGroup, FormControl, Validators} from '@angular/forms'
import { MailerService } from '../mailer.service'
import { Client } from '../client'
import { School } from '../school'

declare var ScrollReveal, window, $: any;

@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.css']
})
export class BranchComponent implements OnInit {

  constructor(
    private mailerService: MailerService,
  ) { }

  formGroup: FormGroup

  school: School = new School()

  ngOnInit() {

    window.scrollTo(0, 0)


  }

  ngAfterViewInit() {
    
    // Srcollreveal Config

    window.sr = ScrollReveal();
    window.sr.reveal('.sr-icons', {
                                  duration: 600,
                                  scale: 0.3,
                                  distance: '0px'
                                }, 200);
    window.sr.reveal('.sr-button', {
                                  duration: 1000,
                                  delay: 200
                                });
    window.sr.reveal('.sr-contact', {
                                duration: 600,
                                scale: 0.3,
                                distance: '0px'
                              }, 300);

  }

  scroll(el) {
    el.scrollIntoView();
  }

}
