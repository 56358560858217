export class School {
    personName: string
    personEmail: string
    personPhone: string

    name: string
    email: string
    phone: string
    contactPerson: string
    contactPersonPosition: string
}