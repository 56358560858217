import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Client } from "./client";
import { School } from "./school";
import { Dto } from "./dto";

import "rxjs/Rx";

@Injectable()
export class MailerService {
  constructor(private http: HttpClient) {}

  private url: string = "https://mailer.alunosbook.com.br/";
  // private url: string = "http://localhost:8080/";

  sendNewClientMail(client: Client): Observable<Dto> {
    return this.http.post(this.url + "sendMail", client).map((res: Dto) => {
      return res;
    });
  }

  sendIndicationMail(school: School): Observable<Dto> {
    // return this.http.post(this.url + '/sendIndicationMail', school )
    //                   .map(
    //                       (res:Response) => res.json()
    //                 )

    return this.http
      .post(this.url + "sendIndicationMail", school)
      .map((res: Dto) => {
        return res;
      });
  }
}
