import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

@Component({
  selector: 'app-resources-nav',
  templateUrl: './resources-nav.component.html',
  styleUrls: ['./resources-nav.component.css']
})
export class ResourcesNavComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit() {

    // switch(this.router.url) {
    //   case '/resources/(resources-content:secretaria)': {
    //     this.selected = 'secretaria'
    //     break;
    //   }
    //   case '/resources/(resources-content:biblioteca)': {
    //     this.selected = 'biblioteca'
    //     break;
    //   }
    //   case '/resources/(resources-content:biblioteca)': {
    //     this.selected = ''
    //     break;
    //   }
    //   case '/resources/(resources-content:biblioteca)': {
    //     this.selected = ''
    //     break;
    //   }
    //   case '/resources/(resources-content:biblioteca)': {
    //     this.selected = ''
    //     break;
    //   }
    //   case '/resources/(resources-content:biblioteca)': {
    //     this.selected = ''
    //     break;
    //   }
    //   case '/resources/(resources-content:biblioteca)': {
    //     this.selected = ''
    //     break;
    //   }
    //   default: {
    //     this.selected = ''
    //   }
    // }

    // this.router.events.subscribe((nav) => {
    //   if(nav && nav['url']){

    //     console.log(nav)
        
          
        
    //   }
    // })
  }

}
