import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MailerService } from "../mailer.service";
import { Client } from "../client";

declare var ScrollReveal, window, $: any;

@Component({
  selector: "app-contact-form",
  templateUrl: "./contact-form.component.html",
  styleUrls: ["./contact-form.component.css"],
})
export class ContactFormComponent implements OnInit {
  constructor(private mailerService: MailerService) {}

  public mask = [
    "(",
    /[1-9]/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];

  formGroup: FormGroup;

  client: Client = new Client();

  positions = [
    "Diretor(a)",
    "Secretário(a)",
    "Professor(a)",
    "Responsável",
    "Aluno(a)",
    "Outro",
  ];

  ngOnInit() {
    window.scrollTo(0, 0);

    this.formGroup = new FormGroup({
      name: new FormControl(this.client.name, [
        Validators.required,
        Validators.minLength(4),
      ]),
      email: new FormControl(this.client.email, [
        Validators.required,
        Validators.email,
        Validators.minLength(4),
      ]),
      phone: new FormControl(this.client.phone, [
        Validators.required,
        // Validators.minLength(10)
      ]),
      school: new FormControl(this.client.school, [
        Validators.required,
        Validators.minLength(4),
      ]),
      position: new FormControl(this.client.position, [Validators.required]),
    });

    this.formGroup.controls["position"].valueChanges.subscribe((position) => {
      this.client.position = position;
    });
  }

  ngAfterViewInit() {
    // Srcollreveal Config

    window.sr = ScrollReveal();
    window.sr.reveal(
      ".sr-icons",
      {
        duration: 600,
        scale: 0.3,
        distance: "0px",
      },
      200
    );
    window.sr.reveal(".sr-button", {
      duration: 1000,
      delay: 200,
    });
    window.sr.reveal(
      ".sr-contact",
      {
        duration: 600,
        scale: 0.3,
        distance: "0px",
      },
      300
    );
  }

  sendMail() {
    this.mailerService.sendNewClientMail(this.client).subscribe(
      (response) => {
        console.log(response);

        if (response.type == "success") {
          alert("Obrigado! Entraremos em contato.");
        } else {
          var erro_messages = "";
          for (let message of response.messages) {
            erro_messages = message + "\n";
          }
          console.log(erro_messages);
        }
      },
      (error) => console.log(error)
    );
  }

  scroll(el) {
    el.scrollIntoView();
  }
}
