import { Component, OnInit } from '@angular/core'
import {FormGroup, FormControl, Validators} from '@angular/forms'
import { MailerService } from '../mailer.service'
import { Client } from '../client'
import { School } from '../school'

declare var ScrollReveal, window, $: any;

@Component({
  selector: 'app-indicate',
  templateUrl: './indicate.component.html',
  styleUrls: ['./indicate.component.css']
})
export class IndicateComponent implements OnInit {

  constructor(
    private mailerService: MailerService,
  ) { }

  formGroup: FormGroup

  school: School = new School()

  ngOnInit() {

    window.scrollTo(0, 0)

    this.formGroup = new FormGroup({
      'personName': new FormControl(this.school.personName, [
        Validators.required,
        Validators.minLength(4),
      ]),
      'personEmail': new FormControl(this.school.personEmail, [
        Validators.required,
        Validators.email,
        Validators.minLength(4),
      ]),
      'personPhone': new FormControl(this.school.personPhone, [
        Validators.required,
        Validators.minLength(4),
      ]),
      'name': new FormControl(this.school.name, [
        Validators.required,
        Validators.minLength(4),
      ]),
      'contactPerson': new FormControl(this.school.contactPerson, [
        Validators.required,
        Validators.minLength(4),
      ]),
      'contactPersonPosition': new FormControl(this.school.contactPersonPosition, [
        Validators.required,
        Validators.minLength(4),
      ]),
    })



  }

  ngAfterViewInit() {
    
    // Srcollreveal Config

    window.sr = ScrollReveal();
    window.sr.reveal('.sr-icons', {
                                  duration: 600,
                                  scale: 0.3,
                                  distance: '0px'
                                }, 200);
  window.sr.reveal('.sr-button', {
                                  duration: 1000,
                                  delay: 200
                                });
  window.sr.reveal('.sr-contact', {
                                duration: 600,
                                scale: 0.3,
                                distance: '0px'
                              }, 300);


  }

  sendMail() {

    this.mailerService.sendIndicationMail(this.school)
                .subscribe(
                    response => {
                      console.log(response)
    
                        if( response.type == 'success') {

                          alert('Obrigado! Entraremos em contato.')

                        } else {
                            var erro_messages = ''
                            for( let message of response.messages) {
                                erro_messages = message + '\n'
                            }
                            console.log(erro_messages)
                        }
                    },
                    error => console.log(error)
                )
    }

    scroll(el) {
      el.scrollIntoView();
    }

}
