import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-ead",
  templateUrl: "./ead.component.html",
  styleUrls: ["./ead.component.css"],
})
export class EadComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
