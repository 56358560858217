import { Component, OnInit } from '@angular/core'
import {HttpClient, HttpHeaders} from '@angular/common/http'

declare var ScrollReveal, window, $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private http: HttpClient
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0)

  }

}
